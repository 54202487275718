import React from 'react'


const Gematria = () => {
  return (
    <div >
    <div className="gematria-section-top">
      
      <img style={{borderRadius:"4px",maxWidth:"500px",padding:"5px"}}src="/images/ff045709f82fa4b1b98a61c59b809dc8.jpg" alt="gematria"/>
    </div>
    </div>
  )
}

export default Gematria
