import React from 'react'


export default class GematriaCalculator extends React.Component{
    constructor(){
        

        super();
        this.state={
            sentence:"",
            hematryMeaningResponse:"",
            hematryIndexResponse:"",
            hematryTranslateResponse:"",
            sumOfIndex:"",
            sumOfMeaning:"",
        };    
    }

    submit(){
        
       if(this.state.sentence!=="" && this.state.sentence.length>1)  
        {console.log(this.state)
        let url="https://hematriaapi.azurewebsites.net/api/Gematria/Calculate";
        let data=this.state;
        fetch(url,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            },
            body:JSON.stringify(data)
        }).then((result)=>{
           
            result.json().then((resp)=>{
                
                
                //ამით ანიჭებ
                //აჰა ასეც შეიძ₾ება
                
                this.setState({hematryMeaningResponse: resp.hematries.map(m => (<li >{m.word}  {m.meaning}</li>)) });
                this.setState({hematryIndexResponse:resp.hematries.map(i => (<li >{i.word}  {i.index}</li>))})
                this.setState({hematryTranslateResponse:resp.hematries.map(i => (<li >{i.word}  {i.majuscule} {i.mkhedruli}</li>))})
                this.setState({sumOfIndex:resp.hematrySum.indexSum})
                this.setState({sumOfMeaning:resp.hematrySum.meaningSum})
                console.warn("rreesponse",this.state.hematryResponse)         
            })
        })
    }else{
        this.setState({hematryMeaningResponse:""})
        this.setState({hematryIndexResponse:""})
        this.setState({hematryTranslateResponse:""})
        this.setState({sumOfIndex:""})
        this.setState({sumOfMeaning:""})
    }}
    render(){
        return(
            <div>
                <div className='textarea-container'>
                <h1>შეიყვანე სიტყვა:</h1>
                <textarea type="text" onKeyUp={()=>{this.submit()}}rows={5} cols={50}  value={this.state.sentence} sentence="sentence"
                 onChange={(data)=>{this.setState({sentence:data.target.value})}} />
                 <h4>თარგმანი</h4>
                 <label id="label1">{this.state.hematryTranslateResponse}</label>
                 <h4>რიცხვითი მნიშვნელობა</h4>
                 <label id="label2">{this.state.hematryMeaningResponse}</label>
            <h4 id='label3'>რიგითი რიცხვი</h4>
            <label id="label4" >{this.state.hematryIndexResponse}</label>
            <h4>რიგით რიცხვთა ჯამი</h4>
            <label id="label5">{this.state.sumOfIndex}</label>
            <h4>რიცხვით მნიშვნელობათა ჯამი</h4>
            <label  id="label6">{this.state.sumOfMeaning}</label>
            </div>
            <div>
          </div>
          <div className="gematria-section-top">
          <p className="primary-text">
      პროექტი hematria.ge ქართულენოვანი ვებ აპლიკაციების პლატფორმაა რომელიც მიზნად
      ისახავს ქართული ჰემატრიის,როგორც უდიდესი ცოდნის გასაღების,პოპულარიზაციას.
      ქართული ანბანი არის დამწერლობა,მათემატიკა,ასტრონომია,კალენდარი.
      "ყოველი საიდუმლოი ამას ენასა შინა დამარხულ არს".
      <img style={{borderRadius:"4px",maxWidth:"500px",padding:"5px"}}src="/images/ff045709f82fa4b1b98a61c59b809dc8.jpg" alt="gematria"/>
      </p>
      </div>
            </div>
        )

    }
}