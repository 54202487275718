import './App.css';
import {Routes,Route} from "react-router-dom";
import Navbar from './components/Navbar';
import Gematria from './pages/Gematria';
import GematriaCalculator from './pages/GematriaCalculator';
import React from 'react';

function App() {
  return (
   <div>
        <React.Fragment>
			<Navbar/>
		</React.Fragment>
      <Routes>
        <Route path='/about' element={<Gematria />}/>
        <Route path='/' element={<GematriaCalculator />}/>
      </Routes>
      </div>
  );
}

export default App;
